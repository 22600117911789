import React, { useState } from 'react';
import { register as registerAPI } from '../../services/api'; // Importando o serviço de registro
import './Register.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== repeatPassword) {
      setError('As senhas não coincidem!');
      return;
    }

    const data = {
      Username: username,
      Password: password,
      RepeatPassword: repeatPassword,
      Email: email,
    };

    try {
      await registerAPI(data); // Removida a variável result, caso não seja necessária
      alert('Registro bem-sucedido!');
      window.location.href = '/login'; // Redireciona para o login após o registro
    } catch (error) {
      setError(error.message || 'Erro ao tentar registrar.');
    }
  };

  return (
    <div className="register-page-container">
      <div className="register-container">
        <h2>Registrar</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Nome de Usuário:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password">Senha:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <label htmlFor="repeatPassword">Repetir Senha:</label>
          <input
            type="password"
            id="repeatPassword"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            required
          />

          <button type="submit">Registrar</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default Register;
