import React, { useState, useEffect } from 'react';
import RankingTabs from './RankingTabs';
import './PlayerRanking.css';
import { fetchPlayerRankings } from '../../services/api'; // Importando a função centralizada

const PlayerRanking = () => {
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadPlayerRankings = async () => {
            try {
                const data = await fetchPlayerRankings(); // Chamando a API centralizada
                setPlayers(data);
            } catch (error) {
                console.error('Erro ao carregar ranking de jogadores:', error);
            } finally {
                setLoading(false);
            }
        };

        loadPlayerRankings();
    }, []);

    return (
        <div className="ranking-container">
            <h1 className="ranking-title">Ranking de Jogadores</h1>
            <RankingTabs />

            {loading ? (
                <div className="loading-message">Carregando dados...</div>
            ) : (
                <table className="ranking-table">
                    <thead>
                        <tr>
                            <th>Posição</th>
                            <th>Nome do Jogador</th>
                            <th>Nível</th>
                            <th>Experiência</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players.length > 0 ? (
                            players.map((player, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{player.name}</td>
                                    <td>{player.level}</td>
                                    <td>{player.exp}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">Nenhum jogador encontrado.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default PlayerRanking;
