// src/components/Ranking/MonthlyTowerRanking.js
import React, { useState, useEffect } from 'react';
import RankingTabs from './RankingTabs'; // Garanta que os tabs estejam aqui
import './MonthlyTowerRanking.css'; // Adicione um CSS se necessário
import { fetchMonthlyTowerRanking } from '../../services/api'; // Importando a função da API centralizada

const MonthlyTowerRanking = () => {
  const [monthlyRankings, setMonthlyRankings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadMonthlyRankings = async () => {
      try {
        const data = await fetchMonthlyTowerRanking(); // Chamada para a API centralizada
        setMonthlyRankings(data);
      } catch (error) {
        console.error('Erro ao carregar o ranking da torre mensal:', error);
      } finally {
        setLoading(false);
      }
    };

    loadMonthlyRankings();
  }, []);

  return (
    <div className="ranking-container">
      <h2 className="ranking-title">Ranking Torre Mensal</h2>
      <RankingTabs />

      {loading ? (
        <div className="loading-message">Carregando dados...</div>
      ) : (
        <table className="ranking-table">
          <thead>
            <tr>
              <th>Ranking</th>
              <th>Nome</th>
              <th>Pontos</th>
              <th>Classe</th>
              <th>Andar Limpo</th>
              <th>Data de Atualização</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {monthlyRankings.length > 0 ? (
              monthlyRankings.map((ranking, index) => (
                <tr key={index}>
                  <td>{ranking.ranking}</td>
                  <td>{ranking.name}</td>
                  <td>{ranking.point}</td>
                  <td>{ranking.class}</td>
                  <td>{ranking.clear_floor}</td>
                  <td>{ranking.update_date}</td>
                  <td>{ranking.kind}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">Nenhum ranking encontrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MonthlyTowerRanking;
