import React from 'react';
import { NavLink } from 'react-router-dom';
import './RankingTabs.css';

const RankingTabs = () => {
    return (
        <div className="ranking-tabs">
            <NavLink to="/rankings/players" activeClassName="active-tab">Ranking de Jogadores</NavLink>
            <NavLink to="/rankings/guilds" activeClassName="active-tab">Ranking de Guildas</NavLink>
            <NavLink to="/rankings/weekly-tower" activeClassName="active-tab">Ranking Torre Semanal</NavLink>
            <NavLink to="/rankings/monthly-tower" activeClassName="active-tab">Ranking Torre Mensal</NavLink>
            <NavLink to="/rankings/guild-honor" activeClassName="active-tab">Ranking Guild Honor</NavLink> {/* Adicionando a nova aba */}
        </div>
    );
};

export default RankingTabs;
