import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
import { fetchNews, addNews, deleteNews } from '../../../services/api';
import './AddNews.css';

const AddNews = () => {
  const [formData, setFormData] = useState({
    category: 'announce',
    language: 'pt-BR',
    name: '',
    description: '',
    image_url: null,
    topnotice: '0',
    username: localStorage.getItem('username') || 'default_user',
  });
  const [existingNews, setExistingNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadNews = async () => {
      try {
        const news = await fetchNews();
        setExistingNews(news);
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);
      }
    };
    loadNews();
  }, []);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormData({ ...formData, description: data });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image_url: e.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = new FormData();
    form.append('category', formData.category);
    form.append('language', formData.language);
    form.append('name', formData.name);
    form.append('description', formData.description);
    form.append('topnotice', formData.topnotice);
    form.append('username', formData.username);

    if (formData.image_url) {
      form.append('file', formData.image_url);
    }

    try {
      const result = await addNews(form);
      alert(result.message || 'Notícia adicionada com sucesso!');
      navigate('/');
    } catch (error) {
      console.error('Erro ao adicionar notícia:', error);
      alert(error.message || 'Erro ao adicionar notícia.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteNews(id);
      alert('Notícia deletada com sucesso!');
      setExistingNews(existingNews.filter((news) => news.id !== id));
    } catch (error) {
      console.error('Erro ao deletar notícia:', error);
      alert('Erro ao deletar notícia.');
    }
  };

  const handleEdit = (news) => {
    navigate(`/dashboard/edit-news/${news.id}`);
  };

  return (
    <div className="add-news-container">
      <h2>Adicionar Notícia</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Categoria</label>
          <select
            name="category"
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          >
            <option value="announce">Anúncio</option>
            <option value="event">Evento</option>
            <option value="maintenance">Manutenção</option>
            <option value="update">Atualização</option>
          </select>
        </div>

        <div className="form-group">
          <label>Idioma</label>
          <select
            name="language"
            onChange={(e) => setFormData({ ...formData, language: e.target.value })}
          >
            <option value="pt-BR">Português</option>
            <option value="en">Inglês</option>
            <option value="es">Espanhol</option>
          </select>
        </div>

        <div className="form-group">
          <label>Título</label>
          <input
            type="text"
            name="name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label>Descrição</label>
          <CKEditor
            editor={ClassicEditor}
            data={formData.description}
            onChange={handleEditorChange}
          />
        </div>

        <div className="form-group">
          <label>Imagem</label>
          <input type="file" name="file" onChange={handleImageChange} />
        </div>

        <div className="form-group">
          <label>Destaque</label>
          <select
            name="topnotice"
            onChange={(e) => setFormData({ ...formData, topnotice: e.target.value })}
          >
            <option value="0">Não</option>
            <option value="1">Sim</option>
          </select>
        </div>

        <button type="submit">Adicionar Notícia</button>
      </form>

      <h2>Notícias Publicadas</h2>
      {existingNews.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Título</th>
              <th>Categoria</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {existingNews.map((news) => (
              <tr key={news.id}>
                <td>{news.name}</td>
                <td>{news.category}</td>
                <td>
                  <button onClick={() => handleEdit(news)}>Editar</button>
                  <button onClick={() => handleDelete(news.id)}>Deletar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhuma notícia disponível.</p>
      )}
    </div>
  );
};

export default AddNews;
