// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Features from './components/Features';
import Footer from './components/Footer';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Download from './components/Download/Download';
import About from './components/About';
import Dashboard from './components/Dashboard/Dashboard';
import AddNews from './components/Dashboard/AddNews/AddNews';
import EditNews from './components/Dashboard/EditNews/EditNews';
import NewsDetail from './components/Dashboard/NewsDetail/NewsDetail';
import NewsList from './components/Dashboard/NewsList/NewsList'; // Importando o NewsList
import ManageNews from './components/Dashboard/ManageNews/ManageNews'; // Import the new ManageNews component
import { AuthProvider } from './context/AuthContext';
import PlayerRanking from './components/Ranking/PlayerRanking';
import GuildRanking from './components/Ranking/GuildRanking';
import WeeklyTowerRanking from './components/Ranking/WeeklyTowerRanking'; // Importando o novo componente
import MonthlyTowerRanking from './components/Ranking/MonthlyTowerRanking'; // Importando o novo componente
import GuildHonorRanking from './components/Ranking/GuildHonorRanking';
import Community from './components/Community';
import CashShop from './components/Dashboard/lojacash/CashShop'; // Importando o componente CashShop

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    return (
        <AuthProvider>
            <Router>
                <div>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/download" element={<Download />} />
                        <Route path="/dashboard/*" element={<Dashboard />} />
                        <Route path="/dashboard/add-news" element={<AddNews />} />
                        <Route path="/dashboard/edit-news/:id" element={<EditNews />} />
                        <Route path="/news" element={<NewsList />} />
                        <Route path="/dashboard/manage-news" element={<ManageNews />} />
                        <Route path="/news/:id" element={<NewsDetail />} />
                        <Route path="/rankings/players" element={<PlayerRanking />} />
                        <Route path="/rankings/guilds" element={<GuildRanking />} />
                        <Route path="/rankings/weekly-tower" element={<WeeklyTowerRanking />} /> {/* Nova rota para a Torre Semanal */}
                        <Route path="/rankings/monthly-tower" element={<MonthlyTowerRanking />} /> {/* Nova rota para a Torre Mensal */}
                        <Route path="/rankings/guild-honor" element={<GuildHonorRanking />} />
                        <Route path="/community" element={<Community />} />
                        <Route path="/cashshop" element={<CashShop />} />  {/* Nova rota para a loja de cash */}
                        
                    </Routes>
                    <Footer />
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
