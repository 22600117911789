// src/components/Community.js
import React from 'react';
import './Community.css'; // CSS para estilizar a página de comunidade
import comunidadeImg from '../assets/images/comunidade.jpg'; // Importando a imagem

const Community = () => {
    return (
        <div className="community-container">
            <img src={comunidadeImg} alt="Comunidade Arcadia" className="community-image" /> {/* Imagem da comunidade */}
            <h2>Comunidade</h2>
            <p className="description">
                Junte-se à nossa comunidade vibrante de jogadores! Conecte-se com outros membros, compartilhe suas experiências e participe de eventos emocionantes. 
                Fique por dentro das últimas novidades, dicas e truques do jogo. Sua jornada no mundo de Arcadia está prestes a se tornar ainda mais emocionante!
            </p>
            <p>Conecte-se conosco nas redes sociais:</p>
            <div className="social-icons">
                <a href="https://discord.gg/SrEqw2xvHA" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-discord"></i> Discord
                </a>
                <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i> Twitter
                </a>
                <a href="https://www.facebook.com/share/g/fTtTF8E6Qpeiuc5t/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i> Facebook
                </a>
                <a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i> Instagram
                </a>
            </div>
        </div>
    );
};

export default Community;
