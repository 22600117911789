import React, { useState, useEffect } from 'react';
import { fetchCashValues, purchaseCash } from '../../../services/api'; // Funções centralizadas

const CashShop = () => {
  const [cashValues, setCashValues] = useState([]);
  const [realBalance, setRealBalance] = useState(localStorage.getItem('realBalance') || 0);
  const username = localStorage.getItem('username'); // Obtém o nome do usuário logado

  // Carregar os valores de cash ao montar o componente
  useEffect(() => {
    const loadCashValues = async () => {
      try {
        const data = await fetchCashValues(); // Chamada centralizada da API
        setCashValues(data);
      } catch (error) {
        console.error('Erro ao carregar valores de cash:', error);
      }
    };

    loadCashValues();
  }, []);

  // Função para realizar a compra
  const handlePurchase = async (cashId) => {
    try {
      const data = await purchaseCash(username, cashId); // Chamada centralizada da API
      if (data.payment_url) {
        // Atualiza o saldo do usuário no frontend
        setRealBalance(data.new_balance);
        localStorage.setItem('realBalance', data.new_balance);

        // Redireciona para o link do pagamento
        window.location.href = data.payment_url;
      } else {
        alert('Erro ao processar pagamento.');
      }
    } catch (error) {
      console.error('Erro ao tentar processar compra:', error);
      alert('Erro ao realizar compra.');
    }
  };

  return (
    <div>
      <h2>Donate</h2>
      <p>Saldo Atual: {realBalance} NX</p>
      <table className="table">
        <thead>
          <tr>
            <th>Gcoin</th>
            <th>Preço (R$)</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {cashValues.length > 0 ? (
            cashValues.map((value) => (
              <tr key={value.Id}>
                <td>{value.GcoinAmount} G</td>
                <td>R$ {value.Price.toFixed(2)}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => handlePurchase(value.Id)}
                  >
                    Comprar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">Nenhuma doação encontrada.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CashShop;
