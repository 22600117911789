import React, { useState, useEffect } from 'react';
import { fetchNews } from '../../services/api';
import './Home.css';

const Home = () => {
  const [news, setNews] = useState([]);
  const [highlightedNews, setHighlightedNews] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Lista de imagens e textos associados
  const slides = [
    {
      image: `${process.env.PUBLIC_URL}/assets/images/background.gif`,
      title: "Bem-vindo ao Arcadia",
      description: "Descubra a experiência MMORPG definitiva",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/images/background2.png`,
      title: "", // Sem texto
      description: "", // Sem descrição
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/images/background.gif`,
      title: "Lançamento CAP 35",
      description: "Prepare-se para novos desafios e aventuras!",
    },
  ];

  useEffect(() => {
    const loadNews = async () => {
      try {
        const data = await fetchNews();
        if (data && data.length > 0) {
          setHighlightedNews(data[0]); // Primeira notícia destacada
          setNews(data.slice(1, 4)); // Próximas 3 notícias
        }
      } catch (error) {
        console.error('Erro ao buscar notícias:', error);
      }
    };

    loadNews();
  }, []);

  // Atualizar o índice do slide para o slideshow
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Troca a cada 5 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar
  }, [slides.length]);

  return (
    <main>
      <section className="hero">
        {/* Fundo dinâmico */}
        <img
          className="background"
          src={slides[currentImageIndex].image} // Usa o índice atual para exibir a imagem
          alt={slides[currentImageIndex].title || "Arcadia Background"}
        />
        <div
          className={`hero-content ${
            !slides[currentImageIndex].title && !slides[currentImageIndex].description
              ? "no-text"
              : ""
          }`}
        >
          {slides[currentImageIndex].title && <h1>{slides[currentImageIndex].title}</h1>}
          {slides[currentImageIndex].description && (
            <p>{slides[currentImageIndex].description}</p>
          )}
          {/* Botão visível somente se o índice atual não for 1 (segunda imagem) */}
          {currentImageIndex !== 1 && (
            <a href="/register" className="cta-btn">
              Junte-se à Aventura
            </a>
          )}
        </div>
      </section>

      <section className="news-section">
        <div className="news-list-container">
          <h2>Notícias</h2>
          <ul>
            {news.map((item) => (
              <li key={item.id}>
                <a href={`/news/${item.id}`} className="news-link">
                  {item.name}
                </a>
                <span className="news-date">
                  {new Date(item.created_at).toLocaleDateString('pt-BR')}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {highlightedNews && (
          <div className="highlighted-news-container">
            <h3>{highlightedNews.name}</h3>
            <p dangerouslySetInnerHTML={{ __html: highlightedNews.description }} />
            <a href={`/news/${highlightedNews.id}`} className="highlighted-link">
              Leia mais &gt;
            </a>
            {highlightedNews.image_url && (
              <img
                src={`${process.env.PUBLIC_URL}${highlightedNews.image_url}`}
                alt={highlightedNews.name}
                className="highlighted-news-image"
              />
            )}
          </div>
        )}
      </section>
    </main>
  );
};

export default Home;
