import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom'; // Adicionar useNavigate
import Sidebar from './Sidebar/Sidebar';
import CashShop from './lojacash/CashShop'; // Importar o componente da loja de cash
import './Dashboard.css';

// Função para converter segundos em horas, minutos e segundos
const formatPlayTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours}h ${minutes}m ${seconds}s`;
};

const Dashboard = () => {
  const [userData, setUserData] = useState({});
  const [characters, setCharacters] = useState([]);
  const navigate = useNavigate(); // Adicionar o useNavigate

  useEffect(() => {
    // Carregar os dados do usuário do localStorage
    const username = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    const lastLogin = localStorage.getItem('lastLogin');
    const realBalance = localStorage.getItem('realBalance');
    const bonusBalance = localStorage.getItem('bonusBalance');
    const isBlocked = localStorage.getItem('isBlocked');
    const registerTime = localStorage.getItem('registerTime');
    
    // Carregar personagens do localStorage
    const storedCharacters = JSON.parse(localStorage.getItem('characters')) || [];

    // Verifica se o usuário está bloqueado ou não
    const status = isBlocked === '1' ? 'Bloqueado' : 'Liberado';

    setUserData({
      username,
      email,
      lastLogin,
      realBalance,
      bonusBalance,
      isBlocked,
      registerTime,
      ip: '189.74.101.224', // Pode ser dinâmico caso necessário
      status
    });

    // Atualizar o estado dos personagens
    setCharacters(storedCharacters);
  }, []);

  // Função para redirecionar para a loja de cash
  const handleRechargeClick = () => {
    navigate('/dashboard/cashshop'); // Redirecionar para a loja de cash
  };

  return (
    <div className="dashboard">
      <Sidebar />
      <main className="main-content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h2>{userData.username}</h2>
          <p>Welcome!</p>
        </div>

        {/* Navegação */}
        <nav>
          <ul>
            <li>
              <Link to="cashshop">Donate</Link> {/* Link para a loja de cash */}
            </li>
          </ul>
        </nav>

        {/* Rotas */}
        <Routes>
          <Route path="cashshop" element={<CashShop username={userData.username} />} /> {/* Passando o username para o CashShop */}
        </Routes>

        {/* User Details */}
        <div className="card mb-4 custom-card">
          <div className="card-body">
            <h5 className="card-title"><i className="fas fa-user"></i> Detalhes da Conta</h5>
            <div className="row">
              <div className="col-md-6">
                <p><i className="fas fa-user"></i> <strong>Username:</strong> {userData.username}</p>
                <p><i className="fas fa-envelope"></i> <strong>Email:</strong> {userData.email}</p>
                <p><i className="fas fa-globe"></i> <strong>IP Atual:</strong> {userData.ip}</p>
                <p><i className="fas fa-unlock-alt"></i> <strong>Status:</strong> {userData.status}</p>
              </div>
              <div className="col-md-6">
                <p><i className="fas fa-map-marker-alt"></i> <strong>Último IP:</strong> {userData.ip}</p>
                <p><i className="fas fa-calendar-alt"></i> <strong>Data de Registro:</strong> {userData.registerTime}</p>
                <p><i className="fas fa-sign-in-alt"></i> <strong>Último Login:</strong> {userData.lastLogin}</p>
              </div>
            </div>
            <button className="btn btn-primary"><i className="fas fa-key"></i> Alterar Senha</button>
          </div>
        </div>

        {/* Saldo */}
        <div className="card mb-4 custom-card">
          <div className="card-body">
            <h5 className="card-title"><i className="fas fa-wallet"></i> Saldo</h5>
            <p><strong>Real Balance:</strong> {userData.realBalance} NX</p>
            <p><strong>Bonus Balance:</strong> {userData.bonusBalance} NX</p>
            <button className="btn btn-secondary" onClick={handleRechargeClick}>
              <i className="fas fa-plus-circle"></i> Recarregar Saldo
            </button> {/* Botão que redireciona para a loja de cash */}
          </div>
        </div>

        {/* Personagens */}
        <div className="card mb-4 custom-card">
          <div className="card-body">
            <h5 className="card-title"><i className="fas fa-users"></i> Personagens</h5>
            {characters.length > 0 ? (
              <table className="table table-dark table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Apelido</th>
                    <th>Classe</th>
                    <th>Nível</th>
                    <th>Mapa</th>
                    <th>Deletado</th>
                    <th>Tempo de Jogo</th>
                  </tr>
                </thead>
                <tbody>
                  {characters.map((character, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{character.Name}</td>
                      <td>{character.Class}</td>
                      <td>{character.Level}</td>
                      <td>{character.MapId}</td>
                      <td>{character.Deleted ? 'Sim' : 'Não'}</td>
                      <td>{formatPlayTime(character.TotalPlayTime)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Nenhum personagem encontrado.</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
