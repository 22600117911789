import React, { useState, useEffect } from 'react';
import RankingTabs from './RankingTabs'; // Garanta que os tabs estejam aqui
import './GuildRanking.css'; // Importando o CSS
import { fetchGuildRankings } from '../../services/api'; // Importando a função centralizada da API

const GuildRanking = () => {
  const [guildRankings, setGuildRankings] = useState([]);

  useEffect(() => {
    const loadGuildRankings = async () => {
      try {
        const data = await fetchGuildRankings(); // Chamada centralizada para API
        setGuildRankings(data);
      } catch (error) {
        console.error('Erro ao carregar o ranking de guildas:', error);
      }
    };

    loadGuildRankings();
  }, []);

  return (
    <div className="ranking-container">
      <h2 className="ranking-title">Ranking de Guilds</h2>
      <RankingTabs />
      <table className="ranking-table">
        <thead>
          <tr>
            <th>Nome da Guilda</th>
            <th>Nível</th>
            <th>Pontos da Guilda</th>
            <th>Símbolo da Guilda</th>
          </tr>
        </thead>
        <tbody>
          {guildRankings.length > 0 ? (
            guildRankings.map((guild, index) => (
              <tr key={index}>
                <td>{guild.name}</td>
                <td>{guild.guild_grade}</td>
                <td>{guild.exploits}</td>
                <td>
                  <img 
                    src={require(`../../assets/images/guildmark/${guild.guild_mark_id}.png`)} 
                    alt={`GuildMark ${guild.name}`} 
                    className="guild-mark" 
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">Nenhuma guilda encontrada.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GuildRanking;
