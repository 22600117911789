import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      {/* Banner de destaque */}
      <div className="banner">
        <h1>Descubra o Mundo de Arcadia</h1>
        <p>Explore, voe e lute em um vasto mundo de fantasia em um MMORPG épico cheio de aventuras.</p>
      </div>

      {/* Seção de Recursos do Jogo */}
      <section className="game-features">
        <div className="container">
          <h2>Principais Recursos</h2>
          <div className="features-grid">
            <div className="feature">
              <img src={require('../assets/images/mounts.jpg')} alt="Montarias Épicas" />
              <h3>Montarias Épicas</h3>
              <p>Domine uma coleção incrível de montarias voadoras e terrestres que podem ser usadas tanto como transporte quanto em combate.</p>
            </div>
            <div className="feature">
              <img src={require('../assets/images/dungeons.jpg')} alt="Desafios de Masmorras" />
              <h3>Desafios de Masmorras</h3>
              <p>Entre em masmorras repletas de perigos, enfrente chefes temíveis e colete recompensas valiosas.</p>
            </div>
            <div className="feature">
              <img src={require('../assets/images/flight_combat.jpg')} alt="Combate Aéreo" />
              <h3>Combate Aéreo</h3>
              <p>Participe de batalhas épicas no céu, usando montarias voadoras em combates aéreos únicos.</p>
            </div>
            <div className="feature">
              <img src={require('../assets/images/open_world.jpg')} alt="Mundo Aberto" />
              <h3>Mundo Aberto</h3>
              <p>Explore paisagens incríveis e descubra segredos em um mundo aberto vasto e diversificado.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Seção de Trailer do Jogo */}
      <section className="game-trailer">
        <div className="container">
          <h2>Trailer de Arcadia</h2>
          <div className="video-container">
            <iframe 
              width="100%" 
              height="500px" 
              src="https://www.youtube.com/embed/MRAFhhMzTr8" 
              title="Arcadia Game Trailer"
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </div>
        </div>
      </section>

      {/* Seção de Classes Jogáveis */}
      <section className="classes-section">
        <div className="container">
          <h2>Classes Jogáveis</h2>
          <p>Escolha entre várias classes, cada uma oferecendo um estilo de combate único e habilidades especiais. Em Arcadia, você encontrará a classe que mais combina com seu estilo de jogo.</p>
          <div className="classes-grid">
            <div className="class-card">
              <img src={require('../assets/images/warrior.jpg')} alt="Guerreiro" />
              <h3>Guerreiro</h3>
              <p>Com espadas e escudos, o Guerreiro lidera a batalha com força bruta e coragem.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/mage.jpg')} alt="Mago" />
              <h3>Mago</h3>
              <p>Manipulador dos elementos, o Mago usa feitiços poderosos para dominar seus oponentes.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/ranger.jpg')} alt="Arqueiro" />
              <h3>Arqueiro</h3>
              <p>Com precisão mortal, o Arqueiro ataca de longe, disparando uma chuva de flechas devastadoras.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/priest.jpg')} alt="Sacerdote" />
              <h3>Sacerdote</h3>
              <p>O Sacerdote cura seus aliados e inflige a ira divina contra seus inimigos.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/assassin.jpg')} alt="Assassino" />
              <h3>Assassino</h3>
              <p>Especialista em ataques furtivos e letalidade, o Assassino elimina seus alvos com precisão.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/guardian.jpg')} alt="Guardião" />
              <h3>Guardião</h3>
              <p>Defensor da justiça, o Guardião utiliza armaduras pesadas e escudos para proteger seus aliados.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/trickster.jpg')} alt="Trickster" />
              <h3>Trickster</h3>
              <p>Mestre das ilusões, o Trickster confunde seus inimigos com truques e habilidades astutas.</p>
            </div>
            <div className="class-card">
              <img src={require('../assets/images/magician.jpg')} alt="Magician" />
              <h3>Magician</h3>
              <p>O Magician invoca forças sobrenaturais para lançar feitiços devastadores contra seus oponentes.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
