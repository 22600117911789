import React, { useState, useEffect } from 'react';
import { fetchNews } from '../../../services/api';
import { useNavigate } from 'react-router-dom'; // Para navegação
import 'bootstrap/dist/css/bootstrap.min.css';
import './NewsList.css';

const NewsList = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    const loadNews = async () => {
      try {
        const data = await fetchNews();
        console.log('Notícias carregadas:', data);
        setNews(data);
      } catch (error) {
        console.error('Erro ao buscar notícias:', error);
      }
    };

    loadNews();
  }, []);

  if (!news) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="container news-list">
      <h2 className="text-center my-4">Últimas Notícias</h2>
      {news.length > 0 ? (
        <div className="row">
          {news.map((item) => (
            <div key={item.id} className="col-md-4 mb-4">
              <div className="card h-100">
                {item.image_url && (
                  <img
                    src={item.image_url}
                    alt={item.name}
                    className="card-img-top news-image"
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title news-title">{item.name}</h5>
                  <p
                    className="card-text news-description"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
                <div className="card-footer">
                  <small className="text-muted">
                    <strong>Categoria:</strong> {item.category} <br />
                    <strong>Publicado em:</strong>{' '}
                    {new Date(item.created_at).toLocaleDateString('pt-BR')}
                  </small>
                  <button
                    onClick={() => navigate(`/news/${item.id}`)} // Usando navegação programática
                    className="btn btn-warning btn-block mt-3"
                  >
                    Leia mais
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">Nenhuma notícia disponível.</p>
      )}
    </div>
  );
};

export default NewsList;
