import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchNewsDetail } from '../../../services/api';
import './NewsDetail.css';

const NewsDetail = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadNewsDetail = async () => {
      try {
        const data = await fetchNewsDetail(id);
        console.log('Detalhes da notícia:', data); // Debug para verificar os dados recebidos
        setNews(data);
      } catch (error) {
        setError('Não foi possível carregar a notícia.');
      }
    };

    loadNewsDetail();
  }, [id]);

  if (error) return <p>{error}</p>;
  if (!news) return <p>Carregando...</p>;

  return (
    <div className="news-detail-container">
      <div className="news-header">
        <h1>{news.name}</h1>
        <p className="news-category"><strong>Categoria:</strong> {news.category}</p>
        <p className="news-date"><strong>Publicado em:</strong> {news.created_at}</p>
      </div>
      {news.image_url && (
        <div className="news-image-container">
          <img src={news.image_url} alt={news.name} className="news-image" />
        </div>
      )}
      <div className="news-content" dangerouslySetInnerHTML={{ __html: news.description }} />
    </div>
  );
};

export default NewsDetail;
