import React from 'react';
import './Download.css';

const Download = () => {
  return (
    <div className="download-page">
      {/* Seção de vídeo de fundo */}
      <div className="video-background">
        <video autoPlay loop muted>
          <source src="../assets/videos/bg-capa.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Seção de destaque com o botão de download */}
      <section className="highlight-section">
        <div className="highlight-content">
          <h1>A Aventura Começa Agora</h1>
          <p>Baixe agora e aproveite a experiência MMORPG definitiva!</p>
          <button className="download-btn">Download</button>
        </div>
      </section>

      {/* Seção de especificações técnicas */}
      <section className="specs-section">
        <h2>Requisitos de Sistema</h2>
        <table className="specs-table">
          <thead>
            <tr>
              <th>Categoria</th>
              <th>Requisitos Mínimos</th>
              <th>Recomendado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sistema Operacional</td>
              <td>Windows Vista (SP2) ou posterior</td>
              <td>Windows Vista (SP2) ou posterior</td>
            </tr>
            <tr>
              <td>Processador</td>
              <td>Intel Core i5-2500k @ 3.3GHz, AMD FX-6200 @ 3.3GHz</td>
              <td>Intel Core i5-2500k @ 3.3GHz, AMD FX-6200 @ 3.3GHz</td>
            </tr>
            <tr>
              <td>Memória</td>
              <td>4GB de RAM</td>
              <td>4GB de RAM</td>
            </tr>
            <tr>
              <td>Placa de Vídeo</td>
              <td>GeForce GTX 460 1G, Radeon HD 6870 1G ou superior</td>
              <td>GeForce GTX 460 1G, Radeon HD 6870 1G ou superior</td>
            </tr>
            <tr>
              <td>DirectX</td>
              <td>Versão 9.0c ou superior</td>
              <td>Versão 9.0c ou superior</td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* Seção de drivers recomendados */}
      <section className="drivers-section">
        <h2>Driver de Gráficos</h2>
        <div className="drivers">
          <div className="driver">
            <img src="../assets/images/nvidia_logo.png" alt="NVIDIA" />
            <a href="https://www.nvidia.com.br/Download/index.aspx?lang=br" className="driver-btn">
              Driver GeForce
            </a>
          </div>
          <div className="driver">
            <img src="../assets/images/amd_logo.png" alt="AMD" />
            <a href="https://www.amd.com/pt/support" className="driver-btn">
              Driver Radeon
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Download;
