import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ManageNews.css';

const ManageNews = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('/get_news');
        const data = await response.json();
        setNewsList(data);
      } catch (error) {
        console.error('Erro ao buscar notícias:', error);
      }
    };

    fetchNews();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Você tem certeza que deseja deletar essa notícia?');
    if (confirmDelete) {
      try {
        await fetch(`/delete_news/${id}`, { method: 'DELETE' });
        setNewsList(newsList.filter((news) => news.id !== id));
      } catch (error) {
        console.error('Erro ao deletar notícia:', error);
      }
    }
  };

  return (
    <div className="manage-news">
      <h2>Gerenciar Notícias</h2>
      <table>
        <thead>
          <tr>
            <th>Título</th>
            <th>Categoria</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {newsList.map((news) => (
            <tr key={news.id}>
              <td>{news.name}</td>
              <td>{news.category}</td>
              <td>
                <Link to={`/dashboard/edit-news/${news.id}`} className="btn-edit">Editar</Link>
                <button onClick={() => handleDelete(news.id)} className="btn-delete">Deletar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageNews;
