import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Substituímos a tag 'a' pela tag 'Link' para navegação interna
import './Sidebar.css';

const Sidebar = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Verificar a permissão do usuário armazenada no localStorage
    const permission = localStorage.getItem('permission');

    // Se a permissão for '1', o usuário é administrador
    if (permission === '1') {
      setIsAdmin(true);
    }
  }, []);

  return (
    <nav className="sidebar">
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link active" to="/dashboard">
            <i className="fas fa-home"></i> Home
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard/cashshop">
            <i className="fas fa-coins"></i> Donate {/* Adicionando o link para a loja de cash */}
          </Link>
        </li>

        {/*<li className="nav-item">
          <Link className="nav-link" to="#">
            <i className="fas fa-shopping-cart"></i> Buy NX
          </Link>
        </li> */}

        <li className="nav-item">
          <Link className="nav-link" to="#">
            <i className="fas fa-store"></i> Em Breve
          </Link>
        </li>

        {/* Menu condicional para administradores */}
        {isAdmin && (
          <li className="nav-item">
            <Link className="nav-link" to="/News">
              <i className="fas fa-newspaper"></i> Notícias
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
