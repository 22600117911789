const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://arcadiaonline.com.br/api" // URL de produção
    : "http://127.0.0.1:5000/api"; // URL de desenvolvimento (incluindo /api)

// Função para verificar respostas da API
const checkResponse = async (response) => {
  try {
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message || "Erro desconhecido");
    }
    return result;
  } catch (error) {
    throw new Error("Erro ao processar a resposta da API");
  }
};

// Função central para chamadas de API
const apiFetch = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, options);
    return checkResponse(response);
  } catch (error) {
    console.error(`Erro na chamada para ${endpoint}:`, error);
    throw error;
  }
};

// --------------------------- Autenticação ---------------------------

// Login do usuário
export const login = (data) =>
	   
  apiFetch("/auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

// Registro de novo usuário
export const register = (data) =>
	   
  apiFetch("/auth/register", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

// --------------------------- Notícias ---------------------------

// Buscar todas as notícias
export const fetchNews = async () => {
  try {
    const data = await apiFetch("/news/get");
    if (!Array.isArray(data)) throw new Error("Dados inválidos recebidos");
    return data;
  } catch (error) {
    console.error("Erro ao buscar notícias:", error);
    throw error;
  }
};

// Buscar uma notícia específica por ID
export const fetchNewsDetail = (id) => apiFetch(`/news/get/${id}`);

// Adicionar uma nova notícia
export const addNews = (formData) =>
  apiFetch("/news/add", {
    method: "POST",
    body: formData,
  });

// Editar uma notícia existente
export const editNews = (id, formData) =>
  apiFetch(`/news/edit/${id}`, {
    method: "PUT",
    body: formData,
  });

// Deletar uma notícia
export const deleteNews = (id) =>
  apiFetch(`/news/delete/${id}`, {
    method: "DELETE",
  });

// --------------------------- Loja de Cash ---------------------------

// Buscar valores de cash disponíveis
export const fetchCashValues = async () => {
  try {
    const data = await apiFetch("/loja/cashvalues");
    if (!Array.isArray(data)) throw new Error("Dados inválidos recebidos");
    // Verificar se há valores de cash disponíveis
    if (data.length === 0) {
      throw new Error("Nenhum valor disponível na loja de cash.");
    }
    return data;
  } catch (error) {
    console.error("Erro ao buscar valores de cash:", error);
    throw error;
  }
};

// Realizar compra de cash
export const purchaseCash = async (username, cashId) => {
  try {
    const data = await apiFetch("/loja/purchase", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, cash_id: cashId }),
    });
    return data;
  } catch (error) {
    console.error("Erro ao realizar compra de cash:", error);
    throw error;
  }
};

// --------------------------- Rankings ---------------------------

// Buscar ranking de jogadores
export const fetchPlayerRankings = async () => {
  try {
    return await apiFetch("/rankings/players");
  } catch (error) {
    console.error("Erro ao buscar ranking de jogadores:", error);
    throw error;
  }
};

// Buscar ranking de guildas
export const fetchGuildRankings = async () => {
  try {
    return await apiFetch("/rankings/guilds");
  } catch (error) {
    console.error("Erro ao buscar ranking de guildas:", error);
    throw error;
  }
};

// Buscar ranking semanal da torre
export const fetchWeeklyTowerRanking = async () => {
  try {
    return await apiFetch("/rankings/last-week-tower");
  } catch (error) {
    console.error("Erro ao buscar ranking semanal da torre:", error);
    throw error;
  }
};

// Buscar ranking mensal da torre
export const fetchMonthlyTowerRanking = async () => {
  try {
    return await apiFetch("/rankings/monthly-tower");
  } catch (error) {
    console.error("Erro ao buscar ranking mensal da torre:", error);
    throw error;
  }
};

// Buscar ranking de honra de guildas
export const fetchGuildHonorRankings = async () => {
  try {
    return await apiFetch("/rankings/guild-honor");
  } catch (error) {
    console.error("Erro ao buscar ranking de honra de guildas:", error);
    throw error;
  }
};

// --------------------------- Utilidades ---------------------------

// Fetch genérico para qualquer outro endpoint
export const genericFetch = (endpoint, options) => apiFetch(endpoint, options);
