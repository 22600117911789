import React from 'react';

const Features = () => {
    return (
        <section className="features">
            <h2>Why Play on Arcadia?</h2>
            <div className="feature">
                <img src="assets/images/game_screenshot.jpg" alt="Gameplay Screenshot" />
                <div className="feature-content">
                    <h3>Exclusive Content</h3>
                    <p>Explore new dungeons, classes, and abilities only available on Arcadia.</p>
                </div>
            </div>
            <div className="feature">
                <img src="assets/images/game_screenshot.jpg" alt="Gameplay Screenshot" />
                <div className="feature-content">
                    <h3>Balanced PVP System</h3>
                    <p>Engage in thrilling PVP battles with balanced mechanics and fair play.</p>
                </div>
            </div>
        </section>
    );
};

export default Features;
