import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { login as loginAPI } from '../../services/api'; // Importando a função de login
import './Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Substituímos "State" por "login"

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { Username: username, Password: password };

    try {
      const result = await loginAPI(data); // Chamando a função de login do serviço de API

      localStorage.setItem('username', result.username);
      localStorage.setItem('email', result.email);
      localStorage.setItem('lastLogin', result.lastLogin);
      localStorage.setItem('realBalance', result.realBalance);
      localStorage.setItem('bonusBalance', result.bonusBalance);
      localStorage.setItem('registerTime', result.registerTime);
      localStorage.setItem('isBlocked', result.isBlocked);
      localStorage.setItem('permission', result.permission);
      localStorage.setItem('characters', JSON.stringify(result.characters));

      login(result.token);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message || 'Erro ao fazer login.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
