import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchNewsDetail, editNews } from '../../../services/api';

const EditNews = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    category: '',
    language: '',
    name: '',
    description: '',
    image_url: null,
    topnotice: '0',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const loadNews = async () => {
      try {
        const data = await fetchNewsDetail(id); // Busca os detalhes da notícia
        setFormData(data);
      } catch (error) {
        console.error('Erro ao carregar notícia:', error);
      }
    };
    loadNews();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });

    try {
      await editNews(id, form); // Chama a API para editar
      alert('Notícia editada com sucesso!');
      navigate('/');
    } catch (error) {
      console.error('Erro ao editar notícia:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={formData.name || ''}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      />
      {/* Outros campos do formulário */}
      <button type="submit">Salvar</button>
    </form>
  );
};

export default EditNews;
