import React, { useState, useEffect } from 'react';
import RankingTabs from './RankingTabs'; // Importando as abas de navegação
import './GuildHonorRanking.css'; // Estilo específico para o ranking de honor
import { fetchGuildHonorRankings } from '../../services/api'; // Função centralizada da API

const GuildHonorRanking = () => {
  const [guildHonorRankings, setGuildHonorRankings] = useState([]);

  useEffect(() => {
    const loadGuildHonorRankings = async () => {
      try {
        const data = await fetchGuildHonorRankings(); // Chamada centralizada
        setGuildHonorRankings(data);
      } catch (error) {
        console.error('Erro ao carregar o ranking de honor das guildas:', error);
      }
    };

    loadGuildHonorRankings();
  }, []);

  return (
    <div className="ranking-container">
      <h2 className="ranking-title">Ranking Guild Honor</h2> {/* Adicionando a classe aqui */}
      <RankingTabs />
      <table className="ranking-table">
        <thead>
          <tr>
            <th>Nome da Guilda</th>
            <th>Simbolo da Guilda</th>
            <th>Grau da Guilda</th>
            <th>Contagem de Membros</th>
            <th>Pontos de Honor</th>
          </tr>
        </thead>
        <tbody>
          {guildHonorRankings.length > 0 ? (
            guildHonorRankings.map((guild, index) => (
              <tr key={index}>
                <td>{guild.guild_name}</td>
                <td>
                  <img 
                    src={require(`../../assets/images/guildmark/${guild.guild_mark_id}.png`)} 
                    alt={`GuildMark ${guild.guild_name}`} 
                    className="guild-mark" 
                  />
                </td>
                <td>{guild.guild_grade}</td>
                <td>{guild.member_count}</td>
                <td>{guild.honor_point}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Nenhuma guilda encontrada.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GuildHonorRanking;
