import React, { useState, useEffect } from 'react';
import RankingTabs from './RankingTabs'; // Importando as abas de navegação
import './WeeklyTowerRanking.css'; // Estilo específico para o ranking semanal

const WeeklyTowerRanking = () => {
  const [weeklyRankings, setWeeklyRankings] = useState([]);

  useEffect(() => {
    fetch('http://127.0.0.1:5000/rankings/last-week-tower')
      .then(response => response.json())
      .then(data => setWeeklyRankings(data))
      .catch(error => console.error('Erro ao carregar o ranking da torre semanal:', error));
  }, []);

  return (
    <div className="ranking-container">
      <h2 className="ranking-title">Ranking da Torre Semanal</h2> {/* Adicionando a classe aqui */}
      <RankingTabs />
      <table className="ranking-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Point</th>
            <th>Class</th>
            <th>Clear Floor</th>
          </tr>
        </thead>
        <tbody>
          {weeklyRankings.length > 0 ? (
            weeklyRankings.map((ranking, index) => (
              <tr key={index}>
                <td>{ranking.name}</td>
                <td>{ranking.point}</td>
                <td>{ranking.class}</td>
                <td>{ranking.clear_floor}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">Nenhum ranking encontrado.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyTowerRanking;
