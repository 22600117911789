// src/components/Header.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './Header.css';

const Header = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src="/assets/images/arcadia_logo.png" alt="Arcadia Logo" />
        </Link>
      </div>
      <nav className="left-menu">
        <ul>
          <li><Link to="/news">Novidades</Link></li> {/* Redirecionando para a listagem de notícias */}
          <li><Link to="/about">Sobre o Jogo</Link></li>
          <li><Link to="/download">Download</Link></li>
          <li><a href="#loja">Loja</a></li>
          <li><Link to="/community">Comunidade</Link></li>
          <li><Link to="/rankings/players">Ranking</Link></li>
          
        </ul>
      </nav>

      <div className="right-menu">
        {isAuthenticated ? (
          <button className="btn logout-btn" onClick={handleLogout}>Logout</button>
        ) : (
          <>
            <Link className="btn login-btn" to="/login">Login</Link>
            <Link className="btn register-btn" to="/register">Register</Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
